@import "../../assets/css/variables.scss";

.skill-card-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(250px, 1fr));
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 10px;
  padding-bottom: 50px;
  max-width: 100vw;
  overflow: hidden;
}

@media screen and (min-width: $screen2-width) {
  .skill-card-container {
    grid-template-columns: repeat(2, 1fr) !important;
    overflow: visible;
  }
}

@media screen and (min-width: $screen4-width) {
  .skill-card-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (min-width: $screen5-width) {
  .skill-card-container {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
