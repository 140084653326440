@import "../../assets/css/variables.scss";

.header {
  background-color: #3d3d3d;
  position: fixed;
  padding: 4px 0;
  z-index: 100;
  max-width: 100vw;
  overflow: hidden;

  header {
    color: white;
    display: flex;
    align-items: center;
    .header-logo-cont {
      max-height: 5rem;
      max-width: 10rem;
      min-width: 9rem;
      img {
        height: 100%;
        width: 100%;
        min-width: 100%;
      }
    }

    .humburger-cont {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      span {
        font-size: 1.6rem;
        cursor: pointer;
      }
    }
    nav {
      flex-grow: 1;
      display: none;
      flex-direction: column;
      position: absolute;
      min-height: 100vh;
      background-color: #5ad7d5;
      left: 0;
      top: 0;
      min-width: 100vw;
      span {
        text-align: right;
        margin: 15px 20px;
        font-size: 1.4rem;
        cursor: pointer;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        list-style-type: none;
        justify-content: flex-end;
        text-align: center;
        li {
          * {
            display: inline-block;
            padding: 15px;
            color: #222;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen4-width) {
  .header {
      border-bottom: solid 2px #ff9f1a;
    header {
      .humburger-cont {
        flex-grow: 1;
        display: none;
        justify-content: flex-end;
      }
      nav {
        flex-grow: 1;
        display: initial;
        flex-direction: row;
        position: initial;
        min-height: initial;
        background-color: initial;
        left: initail;
        top: initial;
        min-width: initial;
        span {
          display: none;
        }
        ul {
          display: flex;
          gap: 0.2rem;
          flex-direction: row;
          list-style-type: none;
          justify-content: flex-end;
          li {
            border-bottom: solid 2px transparent;
            position: relative;
            overflow: hidden;
            * {
              display: inline-block;
              padding: 10px 30px;
              color: #5ad7d5;
              text-decoration: none;

              &:hover{
                  color: white;
              }
            }

            &:hover::before,
            &:hover::after {
              content: "";
              position: absolute;
              bottom: 0;
              min-height: 3px;
              min-width: 100%;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              -webkit-border-radius: 50%;
            }

            &:hover::before {
              background-image: linear-gradient(
                to right,
                transparent,
                #ff9f1a,
                transparent
              );
              animation: nav-link-anim-1 4s infinite;
              -webkit-animation: nav-link-anim-1 4s infinite;
            }

            &:hover::after {
              background-image: linear-gradient(
                to right,
                transparent,
                #ff3838,
                transparent
              );
              animation: nav-link-anim-2 4s infinite;
              -webkit-animation: nav-link-anim-2 4s infinite;
            }
          }
        }
      }
    }
  }
}

@keyframes nav-link-anim-1 {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes nav-link-anim-2 {
  0% {
    left: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
