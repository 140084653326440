.skill-card-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(250px, 1fr))[1];
      grid-template-columns: repeat(1, minmax(250px, 1fr));
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 30px;
  padding-top: 10px;
  padding-bottom: 50px;
  max-width: 100vw;
  overflow: hidden;
}

@media screen and (min-width: 576px) {
  .skill-card-container {
    -ms-grid-columns: (1fr)[2] !important;
        grid-template-columns: repeat(2, 1fr) !important;
    overflow: visible;
  }
}

@media screen and (min-width: 992px) {
  .skill-card-container {
    -ms-grid-columns: (1fr)[3] !important;
        grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (min-width: 1200px) {
  .skill-card-container {
    -ms-grid-columns: (1fr)[4] !important;
        grid-template-columns: repeat(4, 1fr) !important;
  }
}
