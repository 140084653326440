.services-card {
  display: flex;
  position: relative;
  min-height: 230px;
  cursor: pointer;
  background-color: rgba(241, 242, 246, 0.3);
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;
  padding: 25px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: all 0.5s !important;
  -webkit-transition: all 0.5s !important;
  -moz-transition: all 0.5s !important;
  -ms-transition: all 0.5s !important;
  -o-transition: all 0.5s !important;
  border-top: solid 3px var(--color);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: var(--color);
  }

  p {
    font-size: 1.02rem;
    flex-grow: 1;
    text-align: justify;
    opacity: 0.8;
  }

  div {
    display: inline-flex;
    justify-content: flex-end;

    img {
      max-height: 80px;
      min-height: 80px;
      opacity: 0.8;
    }
  }

  &:hover {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    transform: translateY(-5px) scale(1.02) !important;
    -webkit-transform: translateY(-5px) scale(1.02) !important;
    -moz-transform: translateY(-5px) scale(1.02) !important;
    -ms-transform: translateY(-5px) scale(1.02) !important;
    -o-transform: translateY(-5px) scale(1.02) !important;
  }

  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: 900px;
    height: 700px;
    bottom: 30%;
    right: 30%;
    background-color: var(--color);
    opacity: 0.07;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }

  &:hover::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: 900px;
    height: 700px;
    bottom: 10%;
    right: 0;
    background-color: var(--color);
    opacity: 0.07;
  }
}
* {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
