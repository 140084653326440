@import "../../assets/css/variables.scss";

.heading {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  margin-top: 70px;
  margin-bottom: 30px;

  img {
    max-width: 90px;
  }
  p {
    font-size: 34px;
    font-family: "Sansita Swashed", cursive;
    letter-spacing: 2px;
    font-weight: 900;
    color: $heading-color;
  }
}
