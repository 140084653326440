.sidebar-social-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  padding: 16px 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  gap: 8px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  font-size: 28px;
  background-color: #d6f3ff;
  border: solid 2px rgba(179, 57, 57, 0.2);
  border-left: none;
  -webkit-box-shadow: #26394d 0px 20px 30px -10px;
          box-shadow: #26394d 0px 20px 30px -10px;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.sidebar-social-cont:hover {
  background-color: white;
  border-color: #b33939;
}

.sidebar-social-cont .sidebar-social-link {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.sidebar-social-cont .sidebar-social-link:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}

.whatsapp-icon {
  color: #1ab854;
}

.email-icon {
  color: #bb001b;
}

.phone-icon {
  color: #2c3a47;
}

.facebook-icon {
  color: #4267b2;
}

@media (max-width: 768px) {
  .sidebar-social-cont {
    top: initial;
    bottom: 30px;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
