.preloader {
  position: fixed;
  left: 0;
  width: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 9999999;
  background-color: transparent;
  -webkit-transition: 0.9s;
  transition: 0.9s;

  .loader {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: inline-block;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 45%;
    -webkit-transform: translateY(-45%);
    transform: translateY(-45%);

    .loader-outter {
      position: absolute;
      border: 4px solid #54bebc;
      border-left-color: transparent;
      border-bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -webkit-animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
        infinite;
      animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    }

    .loader-inner {
      position: absolute;
      border: 4px solid #54bebc;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      border-right: 0;
      border-top-color: transparent;
      -webkit-animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
        infinite;
      animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    }

    span {
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      color: #54bebc;
      font-size: 25px;
      font-weight: 700;
    }
  }
}

@keyframes loader-outter {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
