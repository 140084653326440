.footer-container {
  background-color: #3d3d3d;
  border-top: solid 2px #f1c40f;
  position: relative;
  padding: 30px 0;
}

.footer-container div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  gap: 5px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
}

.footer-container div strong {
  color: #f1c40f;
  letter-spacing: 1px;
}

.footer-container:before, .footer-container::after {
  width: 50%;
  content: "";
  top: -2px;
  height: 2px;
  position: absolute;
}

.footer-container:before {
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(white), to(transparent));
  background-image: linear-gradient(to right, white, transparent);
}

.footer-container:after {
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(white));
  background-image: linear-gradient(to right, transparent, white);
}

@media screen and (min-width: 576px) {
  .footer-container div {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
