.container {
  max-width: calc(100vw - 20px);
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
}

@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    max-width: 1550px;
  }
}
