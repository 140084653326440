.gotop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: solid 2px #e1b12c;
  font-size: 1.4rem;
  background-color: #374d88;
  color: white;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0.9;
}

.gotop:hover {
  opacity: 1;
  background-color: #273c75;
  transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
}

.goto-visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  animation: goto-show-anim 0.2s forwards;
  -webkit-animation: goto-show-anim 0.2s forwards;
}

@-webkit-keyframes goto-show-anim {
  from {
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    opacity: 0.4;
  }
  to {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes goto-show-anim {
  from {
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    opacity: 0.4;
  }
  to {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
}

.goto-hidden {
  animation: goto-hide-anim 0.2s forwards;
  -webkit-animation: goto-hide-anim 0.2s forwards;
}

@-webkit-keyframes goto-hide-anim {
  from {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes goto-hide-anim {
  from {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (min-width: 576px) {
  .gotop {
    bottom: 30px;
    right: 60px;
  }
}
