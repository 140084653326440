$screen1-width: 320px;
$screen2-width: 576px;
$screen3-width: 768px;
$screen4-width: 992px;
$screen5-width: 1200px;
$screen6-width: 1400px;
$screen7-width: 1920px;

$container1-width: calc(100vw - 20px);
$container2-width: 540px;
$container3-width: 720px;
$container4-width: 960px;
$container5-width: 1140px;
$container6-width: 1320px;
$container7-width: 1550px;

$page_background_color: rgba(52, 172, 224, 0.1);
$text-color: #5ad7d5;
$heading-color: #2f3542;

$skill-card-bg-opacity: 0.4;
