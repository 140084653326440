.heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  padding: 5px;
  margin-top: 70px;
  margin-bottom: 30px;
}

.heading img {
  max-width: 90px;
}

.heading p {
  font-size: 34px;
  font-family: "Sansita Swashed", cursive;
  letter-spacing: 2px;
  font-weight: 900;
  color: #2f3542;
}
