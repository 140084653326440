@import "../../assets/css/variables.scss";

.sidebar-social-cont {
  display: flex;
  flex-direction: column;
  position: fixed;
  padding: 16px 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  gap: 8px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  font-size: 28px;
  background-color: rgb(214, 243, 255);
  border: solid 2px rgba(179, 57, 57, 0.2);
  border-left: none;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(179, 57, 57, 1);
  }

  .sidebar-social-link {
    &:hover {
      transform: scale(1.3);
      -webkit-transform: scale(1.3);
      -moz-transform: scale(1.3);
      -ms-transform: scale(1.3);
      -o-transform: scale(1.3);
    }
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
  }

  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.whatsapp-icon {
  color: #1ab854;
}

.email-icon {
  color: #bb001b;
}

.phone-icon {
  color: rgba(44, 58, 71, 1);
}

.facebook-icon {
  color: #4267b2;
}

@media (max-width: 768px) {
  .sidebar-social-cont {
    top: initial;
    bottom: 30px;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
