@import "../../assets/css/variables.scss";

.social-container {
  background-color: #fff;
  max-width: 100vw;
  overflow: hidden;
}

.social-container {
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 60px 0;
    align-items: center;
    justify-content: center;
  }
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  text-decoration: none;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: white;
  color: var(--icon-color);
  border: solid 1px var(--icon-color);
  &:hover {
    
    transform: translateY(4px) scale(0.95) !important;
    -webkit-transform: translateY(4px) scale(0.95) !important;
    -moz-transform: translateY(4px) scale(0.95) !important;
    -ms-transform: translateY(4px) scale(0.95) !important;
    -o-transform: translateY(4px) scale(0.95) !important;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }
}
