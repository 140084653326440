.contact-form-container {
  position: relative;
  background-image: -webkit-gradient(linear, left top, right top, from(#6878d0), to(#01a4bd));
  background-image: linear-gradient(to right, #6878d0, #01a4bd);
  padding: 5px 0 50px 0;
  max-width: 100vw;
  overflow: hidden !important;
}

.cf-block {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.cf-inp-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 30px;
  color: white;
  margin-bottom: 25px;
}

.cf-inp-row div {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
  -ms-flex-line-pack: center;
      align-content: center;
}

.cf-inp-row div span {
  font-weight: bold;
  text-align: center;
}

.cf-inp-row div input, .cf-inp-row div button {
  padding: 10px;
  outline: none;
  border: solid 2px transparent;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: center;
  font-size: 1.02rem;
  cursor: pointer;
}

.cf-inp-row div input:hover, .cf-inp-row div button:hover {
  border: solid 2px #f1c40f;
}

.cf-inp-row div input:focus, .cf-inp-row div button:focus {
  border: solid 2px #d35400;
}

.cf-inp-row div textarea {
  min-height: 200px;
  padding: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  outline: none;
  border: solid 2px transparent;
  resize: none;
  font-size: 1.02rem;
  cursor: pointer;
}

.cf-inp-row div textarea:hover {
  border: solid 2px #f39c12;
}

.cf-inp-row div textarea:focus {
  border: solid 2px #d35400;
}

.cf-progress-bar {
  position: absolute;
  min-height: 3px;
  background-image: linear-gradient(45deg, #e74c3c, #f39c12, #2ecc71);
  top: -3px;
  overflow: hidden;
}

.cf-progress-bar:before {
  content: "";
  min-height: 3px;
  position: absolute;
  top: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(0, 0, 0, 0.6)), to(transparent));
  background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.6), transparent);
  width: 200px;
  animation: cf-progress-anim 3s infinite;
  -webkit-animation: cf-progress-anim 3s infinite;
}

.cf-submit-btn {
  color: #2c3e50;
  font-weight: 600;
  background-color: #f39c12;
}

.cf-submit-btn:hover {
  color: white;
  background-color: #2c3e50;
}

@-webkit-keyframes cf-progress-anim {
  from {
    left: -100px;
  }
  to {
    left: calc(100% - 100px);
  }
}

@keyframes cf-progress-anim {
  from {
    left: -100px;
  }
  to {
    left: calc(100% - 100px);
  }
}

@media screen and (min-width: 576px) {
  .cf-inp-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .cf-inp-row textarea {
    min-height: 150px;
  }
}
