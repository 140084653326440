.services-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  min-height: 230px;
  cursor: pointer;
  background-color: rgba(241, 242, 246, 0.3);
  border-radius: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  padding: 25px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: all 0.5s !important;
  -webkit-transition: all 0.5s !important;
  -moz-transition: all 0.5s !important;
  -ms-transition: all 0.5s !important;
  -o-transition: all 0.5s !important;
  border-top: solid 3px var(--color);
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.services-card h3 {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--color);
}

.services-card p {
  font-size: 1.02rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: justify;
  opacity: 0.8;
}

.services-card div {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.services-card div img {
  max-height: 80px;
  min-height: 80px;
  opacity: 0.8;
}

.services-card:hover {
  -webkit-box-shadow: #26394d 0px 20px 30px -10px;
          box-shadow: #26394d 0px 20px 30px -10px;
  transform: translateY(-5px) scale(1.02) !important;
  -webkit-transform: translateY(-5px) scale(1.02) !important;
  -moz-transform: translateY(-5px) scale(1.02) !important;
  -ms-transform: translateY(-5px) scale(1.02) !important;
  -o-transform: translateY(-5px) scale(1.02) !important;
}

.services-card:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 900px;
  height: 700px;
  bottom: 30%;
  right: 30%;
  background-color: var(--color);
  opacity: 0.07;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.services-card:hover::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 900px;
  height: 700px;
  bottom: 10%;
  right: 0;
  background-color: var(--color);
  opacity: 0.07;
}

* {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
