@import "../../assets/css/variables.scss";

.contact-form-container {
  position: relative;
  background-image: linear-gradient(to right, #6878d0, #01a4bd);
  padding: 5px 0 50px 0;
  max-width: 100vw;
  overflow: hidden !important;
}

.cf-block {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.cf-inp-row {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: white;
  margin-bottom: 25px;

  div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-content: center;

    span {
      font-weight: bold;
      text-align: center;
    }

    input, button {
      padding: 10px;
      outline: none;
      border: solid 2px transparent;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      text-align: center;
      font-size: 1.02rem;
        cursor: pointer;
      &:hover {
        border: solid 2px #f1c40f;
      }
      &:focus {
        border: solid 2px #d35400;
      }
    }

    textarea {
      min-height: 200px;
      padding: 10px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      outline: none;
      border: solid 2px transparent;
      resize: none;
      font-size: 1.02rem;
      cursor: pointer;
      &:hover {
        border: solid 2px #f39c12;
      }
      &:focus {
        border: solid 2px #d35400;
      }
    }
  }
}

.cf-progress-bar {
  position: absolute;
  min-height: 3px;
  background-image: linear-gradient(45deg, #e74c3c, #f39c12, #2ecc71);
  top: -3px;
  overflow: hidden;

  &:before {
    content: "";
    min-height: 3px;
    position: absolute;
    top: 0;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.6),
      transparent
    );
    width: 200px;
    animation: cf-progress-anim 3s infinite;
    -webkit-animation: cf-progress-anim 3s infinite;
  }
}

.cf-submit-btn{
    color: #2c3e50;
    font-weight: 600;
    background-color: #f39c12;

    &:hover{
        color: white;
        background-color: #2c3e50;
    }
}

@keyframes cf-progress-anim {
  from {
    left: -100px;
  }
  to {
    left: calc(100% - 100px);
  }
}

@media screen and (min-width: $screen2-width) {
  .cf-inp-row {
    flex-direction: row;
    textarea{
        min-height: 150px;
    }
  }
}
