.header {
  background-color: #3d3d3d;
  position: fixed;
  padding: 4px 0;
  z-index: 100;
  max-width: 100vw;
  overflow: hidden;
}

.header header {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header header .header-logo-cont {
  max-height: 5rem;
  max-width: 10rem;
  min-width: 9rem;
}

.header header .header-logo-cont img {
  height: 100%;
  width: 100%;
  min-width: 100%;
}

.header header .humburger-cont {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.header header .humburger-cont span {
  font-size: 1.6rem;
  cursor: pointer;
}

.header header nav {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  min-height: 100vh;
  background-color: #5ad7d5;
  left: 0;
  top: 0;
  min-width: 100vw;
}

.header header nav span {
  text-align: right;
  margin: 15px 20px;
  font-size: 1.4rem;
  cursor: pointer;
}

.header header nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.4rem;
  list-style-type: none;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  text-align: center;
}

.header header nav ul li * {
  display: inline-block;
  padding: 15px;
  color: #222;
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  .header {
    border-bottom: solid 2px #ff9f1a;
  }
  .header header .humburger-cont {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: none;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .header header nav {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: initial;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    position: initial;
    min-height: initial;
    background-color: initial;
    left: initail;
    top: initial;
    min-width: initial;
  }
  .header header nav span {
    display: none;
  }
  .header header nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.2rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    list-style-type: none;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .header header nav ul li {
    border-bottom: solid 2px transparent;
    position: relative;
    overflow: hidden;
  }
  .header header nav ul li * {
    display: inline-block;
    padding: 10px 30px;
    color: #5ad7d5;
    text-decoration: none;
  }
  .header header nav ul li *:hover {
    color: white;
  }
  .header header nav ul li:hover::before, .header header nav ul li:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    min-height: 3px;
    min-width: 100%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-border-radius: 50%;
  }
  .header header nav ul li:hover::before {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#ff9f1a), to(transparent));
    background-image: linear-gradient(to right, transparent, #ff9f1a, transparent);
    animation: nav-link-anim-1 4s infinite;
    -webkit-animation: nav-link-anim-1 4s infinite;
  }
  .header header nav ul li:hover::after {
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#ff3838), to(transparent));
    background-image: linear-gradient(to right, transparent, #ff3838, transparent);
    animation: nav-link-anim-2 4s infinite;
    -webkit-animation: nav-link-anim-2 4s infinite;
  }
}

@-webkit-keyframes nav-link-anim-1 {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes nav-link-anim-1 {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes nav-link-anim-2 {
  0% {
    left: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

@keyframes nav-link-anim-2 {
  0% {
    left: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
